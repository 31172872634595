/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Box } from '@chakra-ui/react';

type CardProps = {
  heading?: React.ReactNode;
  /** content to render inside card */
  children: React.ReactNode;
};

export const Card = <T extends {}>({
  heading,
  children,
  ...rest
}: CardProps & T) => (
  /** We can revisit adding shadow="sm"; fow now disabled to support dark mode */
  <Box rounded="lg" overflow="hidden" borderWidth="1px" {...rest}>
    {heading ? (
      <Box
        px="4"
        py="5"
        borderBottom="1px"
        borderColor="gray.200"
        fontSize="lg"
        color="gray.900"
        fontWeight="medium"
        lineHeight="leading-6"
      >
        {heading}
      </Box>
    ) : null}
    {children}
  </Box>
);
