/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const CardContent = ({ children, ...rest }: BoxProps) => (
  <Box
    /**
     * NOTE: Unsure why it is 4rem vs 5rem
     * We will need to revisit for a more robust scroll solution
     */
    height="calc(100% - 4rem)"
    px="4"
    py="5"
    overflow="auto"
    {...rest}
  >
    {children}
  </Box>
);
