/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Stack, Button, Container, Flex, Divider, Img } from '@chakra-ui/react';
import ContentContainer from '@/src/shared/components/ContentContainer';
import { Card, CardContent } from '@/src/shared/components/Card';
import { signIn } from 'next-auth/client';
import BackgroundImage from '@/public/static/petal.jpg';

const LogIn = () => (
  <Container>
    <Card heading="Credentials Required">
      <CardContent>
        <Stack shouldWrapChildren spacing="4">
          {/* <Flex justifyContent="center">
            <Text fontSize={20} fontFamily="Stratum">
              MATTERNET
            </Text>
          </Flex> */}
          <Img src={BackgroundImage} rounded="lg" />
          <Divider />
          <Flex justifyContent="center">
            {/* Here we specify the exact auth provider we want the client to use */}
            <Button
              colorScheme="blue"
              onClick={() => signIn('auth0')}
              width="50%"
            >
              Log In
            </Button>
          </Flex>
        </Stack>
      </CardContent>
    </Card>
  </Container>
);

export default LogIn;
